.inmatedetails {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .header {
    width: 100%;
    font-weight: 400;
    font-size: 16px;

    border-bottom: 1px solid #232320;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
  }
  .content {
    overflow-y: scroll;
    padding: 8px 16px;
  }
  .name {
    color: #ededec;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
  }
}

.list {
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .item {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
  }
}

.data {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;

  .block {
    border: 1px dashed #717069;
    border-radius: 8px;
    flex: 1;
    height: 76px;
    padding: 16px 16px;

    .value {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #ededec;
    }
    .label {
      font-weight: 400;
      font-size: 14px;
      min-width: 100px;
      line-height: 22px;
    }
  }
}
.bothopen {
  height: 60%;
}
