@keyframes flash {
    0% { background-color: black; color: black; }
    50% { background-color: #d87a16; color: white; }
    100% { background-color: black; color: black; }
  }
  .alert-list{
    .flash {
      animation: flash 1s linear 3;
    }

    .h-40 {
      max-height: 40vh;
      // height: calc(100% - 50px);
    }

    .h-60 {
      max-height: 60vh;
      border: 2px solid yellow;
    }
  }