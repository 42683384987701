.footer {
  display: flex;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  color: #acacac;
}

.selectInmate {
  width: 294px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #2e2e2b;
  font-size: 16px;
  font-style: italic;
}

.fadeBase {
  opacity: 0;
  transition: opacity 300ms;
}
.fadeIn {
  opacity: 1;
}
.fadeOut {
  opacity: 0;
}
