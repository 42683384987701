.podalerts {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  overflow-y: scroll;
  border-bottom: 1px solid #000000;

  .alert {
    padding: 8px 16px;
    background: #262626;
    border-radius: 8px;
    display: flex;
    gap: 16px;
    align-items: center;
    cursor: pointer;

    .icon {
      background: #434343;
      border-radius: 100px;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .content {
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .title {
      display: flex;
      gap: 8px;
      align-items: flex-end;

      .type {
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        color: #dbdbdb;
      }
      .time {
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        color: #acacac;
      }
    }
    .inmateid {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #acacac;
    }
  }
}
.bothopen {
  height: 40%;
}
