.riskChartContainer {
  height: 100%
}

.figure-key-list li {
  margin: 0 0 8px;
  padding: 0;
  color: white;
  display: flex;
  justify-content: space-between;
}

.shape-square {
  display: inline-block;
  vertical-align: middle;
  width: 13px;
  height: 13px;
}

  