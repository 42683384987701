// SVG_____
.red-circle {
  border: 4px solid rgb(115, 111, 111);
}

.green-circle {
  border: 4px solid rgb(115, 111, 111);
}

.blue-circle {
  border: 4px solid rgb(115, 111, 111);
}

.stroke-circle {
  border: 1px solid grey;
}

.click-area-circle {
  fill: transparent;
}

.ellipseText {
  // fill: white;
  font-size: 12px;
  font-weight: 400;
  z-index: 100;
}

// end SVG____

.container {
  height: 94.4%;
  display: flex;
}

@keyframes blink {
  0%,
  49% {
    visibility: visible;
  }
  50%,
  100% {
    visibility: hidden;
  }
}

.blink {
  animation: blink 1s linear infinite;
}
