.inmateDetails {
    width: 100%;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    .total {
        border: 0;
        text-align: center;
        font-weight: 700;
        text-align: -webkit-center;
    }
    
    .total h1 {
        font-size: 20px;
        white-space: nowrap;
        line-height: 1;
        margin-bottom: 0;
        letter-spacing: 1px;
    }
    
    .total h2 {
        font-size: 78px;
        line-height: 1;
        letter-spacing: 4px;
    }
    .inmateDetails--pod {
        &:hover {
            background: #D9D9D9;
            color: black;
            cursor: pointer;
        }
    }
    p {
        font-size: 16px;
        line-height: 1;
        margin-bottom: 0.5rem;
        white-space: nowrap;
    }
    span {
        font-size: 32px;
        font-weight: 700;
        margin: 0;
        line-height: 1;
    }
}
