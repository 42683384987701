.podalertToggle {
  width: 324px;
  display: flex;
  align-items: center;
  border-left: 1px solid #232320;
  padding: 16px;
  justify-content: space-between;
  cursor: pointer;

  .label {
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    color: #acacac;
  }
}
.alertdot {
  position: absolute;
  top: 3px;
  right: -3px;
  width: 8px;
  height: 8px;
  background: #e84749;
  border: 2px solid #1b1b18;
  border-radius: 4px;
}

.sidebar {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: #141414;
  width: 324px;
  flex-direction: column;
  padding: 0px;
  gap: 16px;
  border-width: 1px 0px 0px 1px;
  border-style: solid;
  border-color: #232320;
  color: #acacac;
  overflow: hidden;
}
.sidebarfill {
  width: 324px;
  height: 100%;
}

.sidebarBase {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: -324px;
  height: 100%;
  transition: right 300ms;
}
.sidebarIn {
  right: 0px;
}
.sidebarOut {
  right: -324px;
}

.sidebarfillBase {
  height: 100%;
  width: 0px;
  transition: width 300ms;
}

.sidebarfillIn {
  width: 324px;
}
.sidebarfillOut {
  width: 0px;
}

.alertsBase {
  height: 0%;
  transition: height 300ms;
}
.alertsPartialIn {
  height: 40%;
}
.alertsFullIn {
  height: 100%;
}
.alertsOut {
  height: 0%;
}

.inmateDetailsBase {
  position: relative;
  height: 100%;
  // right: -324px;
  transition: right 300ms;
}
.inmateDetailsIn {
  right: 0px;
}
.inmateDetailsOut {
  right: -324px;
}
