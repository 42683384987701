//
// Card
//

.card-height {
  min-height: 267px;
}

// Base
.card {
  @if ($card-border-enabled) {
    border: $card-border-width $card-border-style var(--kt-card-border-color);
  }

  @else {
    border: 0;
  }

  // box-shadow: var(--kt-card-box-shadow);
  background-color: var(--kt-card-bg);
  box-shadow: 1px solid #d87a16;
  // border: 1px solid #d87a16;
  //  width: 150%;

  // Header
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    min-height: $card-header-height;
    padding: 0 $card-px;
    color: var(--kt-card-cap-color);
    background-color: var(--kt-card-cap-bg);
    border-bottom: $card-border-width $card-border-style var(--kt-card-border-color);

    // Title
    .card-title {
      display: flex;
      align-items: center;
      margin: $card-header-py;
      margin-left: 0;

      &.flex-column {
        align-items: flex-start;
        justify-content: center;
      }

      .card-icon {
        margin-right: 0.75rem;
        line-height: 0;

        i {
          font-size: 1.25rem;
          color: var(--kt-gray-600);
          line-height: 0;

          &:after,
          &:before {
            line-height: 0;
          }
        }

        .svg-icon {
          color: var(--kt-gray-600);
          @include svg-icon-size(24px);
        }
      }

      &,
      .card-label {
        font-weight: 500;
        color: var(--kt-text-dark);
      }

      .card-label {
        margin: 0 0.75rem 0 0;
        flex-wrap: wrap;
      }

      // Description
      small {
        color: var(--kt-text-muted);
        font-size: 1rem;
      }

      // Headings
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin-bottom: 0;
      }
    }

    // Toolbar
    .card-toolbar {
      display: flex;
      align-items: center;
      margin: $card-header-py 0;
      flex-wrap: wrap;
    }
  }

  // Body
  .card-body {
    padding: $card-py $card-px;
    color: var(--kt-card-color);
  }

  // Footer
  .card-footer {
    padding: $card-py $card-px;
    color: var(--kt-card-cap-color);
    background-color: var(--kt-card-cap-bg);
    border-top: $card-border-width $card-border-style var(--kt-card-border-color);
  }

  // Scroll
  .card-scroll {
    position: relative;
    overflow: auto;
  }

  // Reset padding x
  &.card-px-0 {

    .card-header,
    .card-body,
    .card-footer {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.card-py-0 {

    .card-header,
    .card-body,
    .card-footer {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &.card-p-0 {

    .card-header,
    .card-body,
    .card-footer {
      padding: 0;
    }
  }

  // Dashed style
  &.card-dashed {
    box-shadow: none;
    border: $card-border-width dashed var(--kt-card-border-dashed-color);

    >.card-header {
      border-bottom: 1px dashed var(--kt-card-border-dashed-color);
    }

    >.card-footer {
      border-top: 1px dashed var(--kt-card-border-dashed-color);
    }
  }

  // Bordered style
  &.card-bordered {
    box-shadow: none;
    border: $card-border-width $card-border-style var(--kt-card-border-color);
  }

  // Flush header and footer borders
  &.card-flush {
    >.card-header {
      border-bottom: 0 !important;
    }

    >.card-footer {
      border-top: 0 !important;
    }
  }

  // Enable shadow
  &.card-shadow {
    box-shadow: var(--kt-card-box-shadow);
    border: 0;
  }

  // Reset styles
  &.card-reset {
    border: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;

    >.card-header {
      border-bottom: 0 !important;
    }

    >.card-footer {
      border-top: 0 !important;
    }
  }
}

// Responsive stretch heights
.card {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      // Stretch
      &.card#{$infix}-stretch {
        height: calc(100% - var(--bs-gutter-y));
      }

      // Stretch 75
      &.card#{$infix}-stretch-75 {
        height: calc(75% - var(--bs-gutter-y));
      }

      // Stretch 50
      &.card#{$infix}-stretch-50 {
        height: calc(50% - var(--bs-gutter-y));
      }

      // Stretch 33
      &.card#{$infix}-stretch-33 {
        height: calc(33.333% - var(--bs-gutter-y));
      }

      // Stretch 25
      &.card#{$infix}-stretch-25 {
        height: calc(25% - var(--bs-gutter-y));
      }

      // Header stretch
      .card-header#{$infix}-stretch {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        align-items: stretch;

        .card-toolbar {
          margin: 0;
          align-items: stretch;
        }
      }
    }
  }
}

// Utilities
.card-p {
  padding: $card-py $card-px  !important;
}

.card-px {
  padding-left: $card-px  !important;
  padding-right: $card-px  !important;
}

.card-shadow {
  box-shadow: var(--kt-card-box-shadow);
}

.card-py {
  padding-top: $card-py  !important;
  padding-bottom: $card-py  !important;
}

.card-rounded {
  border-radius: $card-border-radius;
}

.card-rounded-start {
  border-top-left-radius: $card-border-radius;
  border-bottom-left-radius: $card-border-radius;
}

.card-rounded-end {
  border-top-right-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}

.card-rounded-top {
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
}

.card-rounded-bottom {
  border-bottom-left-radius: $card-border-radius;
  border-bottom-right-radius: $card-border-radius;
}

// Mobile mode
@include media-breakpoint-down(md) {
  .card {
    >.card-header:not(.flex-nowrap) {
      padding-top: $card-header-py;
      padding-bottom: $card-header-py;
    }
  }
}

.dx-datagrid {
  color: #fff;
  background-color: transparent !important;
}

.dx-datagrid-header-panel {
  border-bottom: none !important;
  padding: 0;
  background-color: transparent !important;
}

.dx-toolbar {
  background-color: transparent !important;
  color: #fff;
  padding: 0;
  overflow: visible;
}

.dx-datagrid-headers {
  color: rgba(255, 255, 255, 0.6);
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  border-bottom: 1px solid #303030 !important;
  // border-right: 1px solid #303030 !important;
  background: #1d1d1d !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row>td {
  border-bottom: 1px solid #303030 !important;
  // border-right: 1px solid #303030 !important;
  font-size: 14px;
  font-weight: 500;
  color: $light-white;
}

.dx-datagrid .dx-row>td {
  border-bottom: 1px solid #303030 !important;
  // height: 74px;
  color: $grey-white;
  // border-right: 1px solid #303030 !important;
}

.dx-pager .dx-pages .dx-selection,
.dx-pager .dx-page-sizes .dx-selection {
  font-weight: 500;
  // background-color: #d87a16 !important;
  // color: rgba(0, 0, 0, 0.87);

  color: #d87a16;
    background-color: #1c1c1c;
    border: 1px solid #d87a16;
    border-radius: 10px;
}
.dx-datagrid-content .dx-datagrid-table .dx-row>td, .dx-datagrid-content .dx-datagrid-table .dx-row>tr>td, .dx-datagrid .dx-row>td{
  vertical-align: middle;
}


.dx-header-row > td > .dx-datagrid-text-content:not(.dx-sort-indicator):not(.dx-header-filter-indicator) {
  max-width: 100%;
  color: #FFFFFF;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td, .dx-datagrid-content .dx-datagrid-table .dx-row > tr > td, .dx-datagrid .dx-row > td {
  vertical-align: middle;
  color: #DEDEDE;
}

.dx-datagrid {
  color: $light-white  !important;
  background-color: transparent !important;
}

.dx-datagrid-filter-row {
  background-color: transparent !important;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td:first-of-type {
  // border-left: 1px solid #303030 !important;
  font-size: 14px;
  font-weight: 500;
  color: $light-white;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row>td:hover:not(.dx-command-select):not(.dx-command-expand):not(.dx-editor-cell):not(.dx-command-edit):not(.dx-datagrid-group-space) {
  background-color: #262626;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row>td .dx-sort-indicator {
  font-size: 14px;
  font-weight: 500;
  color: $light-white;
}

.dx-datagrid .dx-data-row td:first-child {
  color: #d87a16;
}

tr.dx-row.dx-data-row.dx-row-lines:hover {
  background-color: #262626 !important;
}
.dx-data-row:hover{
  background-color: #262626;
}
.dx-datagrid .dx-link {
  color: #d87a16;
}

.dx-button-mode-text.dx-button-default {
  color: #fff;
  background-color: #d87a16;
  border-color: #d87a16;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  border-radius: 12px;
}

.card-border {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: var(--kt-gray-300);
}
  