.round-select.MuiInputBase-formControl {
  border-radius: 24px;
  min-width: 160px;
}
.round-select-form .MuiSelect-select {
  padding: 4px 6px 4px 0px;
  width: 100%;
}

.round-select-item {
  padding-left: 0px;
  padding-right: 0px;
}
.selectMenuHeader {
  color: #a1a09a;
  font-size: 12px;
  line-height: 24px;
  font-weight: 400;
  padding: 0 8px;
}
.round-select-form{
  .round-select-label {
    position: absolute;
    top: -13px;
    left: 24px;
    width: 50px;
    height: 30px;
    font-size: 14px;
  }
}